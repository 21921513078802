//export const BASE_URL = process.env.NODE_ENV ==='development' ? 'http://localhost:5000':'';
export const BASE_URL = "";
export const PRODUCTS_URL = "/api/products";
export const REPORTS_URL = "/api/reports";
export const ROLES_URL = "/api/roles";
export const USERS_URL = "/api/users";
export const UPLOAD_URL = "/api/upload";
export const DISPLAY_URL = "/api/display";
export const TIME_ZONE = "America/New_York";
export const conditionReasonsList = [
  {
    label: "Small Scratch",
    value: "smallScratch",
  },
  {
    label: "Small Crack",
    value: "smallCrack",
  },
  {
    label: "Open Box",
    value: "openBox",
  },
  {
    label: "Storage Damage",
    value: "storageDamage",
  },
  {
    label: "Painted",
    value: "painted",
  },
  {
    label: "Dents",
    value: "dents",
  },
  {
    label: "Scuff",
    value: "scuff",
  },
  {
    label: "Dust",
    value: "dust",
  },
  {
    label: "See Comments",
    value: "seeComments",
  },
];
export const conditionsList = [
  {
    label: "Please choose condition",
    value: "",
  },
  {
    label: "New",
    value: "New",
  },
  {
    label: "New Other",
    value: "NewOther",
  },
  {
    label: "Repaired",
    value: "Repaired",
  },
  {
    label: "Used",
    value: "Used",
  },
  {
    label: "Used w/ damage",
    value: "Used w/ damage",
  },
  {
    label: "Needs programming",
    value: "Needs programming",
  },
  {
    label: 'For parts only"',
    value: 'For parts only"',
  },
];

export const freightOptions = [
  "",
  "200",
  "250",
  "300",
  "350",
  "400",
  "450",
  "500",
  "550",
  "600",
  "650",
];

export const statusOptions = [
  "Unlisted",
  "UnlistedWProblems",
  "CheapItem",
  "InProgress",
  "New",
  "Listed",
  "ListedWProblems",
  "Revision",
  "Sold",
  
];
export const roleStatusOptions = [
  { label: "Please choose permission", value: "" },
  { label: "New", value: "New" },
  { label: "Revision", value: "Revision" },
  { label: "Sold", value: "Sold" },
  { label: "Unlisted", value: "Unlisted" },
  { label: "UnlistedWProblems", value: "UnlistedWProblems" },
  { label: "InProgress", value: "InProgress" },
  { label: "Listed", value: "Listed" },
  { label: "ListedWProblems", value: "ListedWProblems" },
  { label: "CheapItem", value: "CheapItem" },
];
export const permissionsList = [
  {
    label: "Can Access Product List",
    value: "can_access_products",
  },
  {
    label: "Can Access Sold Items (Menu Tab)",
    value: "can_access_sold_items",
  },
  {
    label: "Can Access Listed Items (Menu Tab)",
    value: "can_access_listed_items",
  },
  {
    label: "Can Access Idle Items(Menu Tab)",
    value: "can_access_idle_items",
  },
  {
    label: "Can Access Cheap Items(Menu Tab)",
    value: "can_access_cheap_items",
  },
  {
    label: "Can Access Reports(Menu Tab)",
    value: "can_access_reports",
  },
  {
    label: "Can Access Parts Picker (Menu Tab)",
    value: "can_access_tools",
  },
  {
    label: "Can Access Users(Menu Tab)",
    value: "can_access_users",
  },
  {
    label: "Can Access Roles (Menu Tab)",
    value: "can_access_roles",
  },
  {
    label: "Can Assign Products To me ",
    value: "can_assign_product_to_me",
  },

  {
    label: "Can Create Product (Add Product Button)",
    value: "can_create_product",
  },
  {
    label: "Can Update Product (Update Product Button)",
    value: "can_update_product",
  },

  {
    label: "Can Delete Product (Delete Product Button) ",
    value: "can_delete_product",
  },
  {
    label: "Can Upload Photos (Edit Product Page)",
    value: "can_upload_photo",
  },
  {
    label: "Can Upload Public Photos (Edit Product Page)",
    value: "can_upload_public_photo",
  },
  {
    label: "Can Select only first item (Product List View)",
    value: "can_select_only_first_item",
  },
  {
    label: "Can View Search Box (Product List View)",
    value: "can_view_search_box",
  },
  {
    label: "Can Edit Product Fields (Edit product page)",
    value: "can_edit_product_fields",
  },

  {
    label:
      "Can Edit Comment (Edit product page: as default editable only in case of UnlistedWProblems status)",
    value: "can_edit_comment",
  },
  {
    label:
      "Can Edit Product Price (Edit product page)",
    value: "can_edit_product_price",
  },
  {
    label: "Can Search by Modifier (Search Box) ",
    value: "can_search_by_modifier",
  },
  //Statuses
  {
    label: "Can View New Status (Status in Edit Product Page)",
    value: "can_view_New_status",
  },
  {
    label: "Can View Revision Status (Status in Edit Product Page)",
    value: "can_view_Revision_status",
  },
  {
    label: "Can View Sold Status (Status in Edit Product Page) ",
    value: "can_view_Sold_status",
  },
  {
    label: "Can View Unlisted Status (Status in Edit Product Page)",
    value: "can_view_Unlisted_status",
  },
  {
    label: "Can View Unlisted W Problems Status (Status in Edit Product Page)",
    value: "can_view_UnlistedWProblems_status",
  },
  {
    label: "Can View InProgress Status (Status in Edit Product Page)",
    value: "can_view_InProgress_status",
  },
  {
    label: "Can View Listed Status (Status in Edit Product Page)",
    value: "can_view_Listed_status",
  },

  {
    label: "Can View ListedWProblems Status (Status in Edit Product Page)",
    value: "can_view_ListedWProblems_status",
  },
  {
    label: "Can View CheapItem Status (Status in Edit Product Page)",
    value: "can_view_CheapItem_status",
  },
 
];
